import React, {useCallback, useEffect, useState} from "react";
import Aux from "../../hoc/Auxiliary"
import PageDetails from "../../components/DataUpload/PageDetails/PageDetails";
import CategoriesData from "../../components/DataUpload/CategoriesData/CategoriesData";
import ActionsList from "../../components/DataUpload/ActionsList/ActionsList";
import HeaderBreadCrumb, {normalizePathItem} from "../../components/BreadCrumb/Breadcrumb";
import {
    findByDCAndMarketNode, getLevel5ValidationStatus, getValidationProgressStatus,
    getWriteAccessMarketNodeDetails,
    persistData,
    publishData
} from "../../api/Category/category-service";
import runMarketNodeValidation from "../../api/MarketNode/marketnode-service";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {loadState, removeFromSessionStorage} from "../../api/State/manage-state-storage-service";
import {showError, showInfo, showSuccess, showTimedError, showWarn} from "../../api/notification/ToastManager";
import {
    isLevel4ButtonDisabled,
    isTemplatesInProgress,
    isValidationInProgress
} from "../../api/MarketNode/marketNode-status";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {onServerCallError} from "../../api/utils/errors";
import {getAllQueryFileNamesPerDataCollection} from "../../api/file/upload-service";
import {StatusCode, StatusContext} from "../../entities/StatusContext";
import {updateMarketNodeStatus} from "../../api/dataCollection/dataCollection-service";



const DataUploadBuilder = () => {
    const {state} = useLocation();
    const marketNode = loadState(state, "marketNode");
    const dataCollection = loadState(state, "dataCollection");
    const dcMarketNodeId = loadState(state, "dcMarketNodeId");
    const urlParams = useParams();
    const [categories, setCategories] = useState([]);
    const [statusLV4, setStatusLV4] = useState(false);
    const [btnPublishVisibility, setBtnPublishVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isMarketNodeDataPublishing, setIsMarketNodeDataPublishing] = useState(false);
    const persistingLabel = "Persisting Data ..."
    const [publishBtnLabel, setPublishBtnLabel] = useState("Publish Data");
    const [crumbs, setCrumbs] = useState(["Data Collections"])
    const navigate = useNavigate();
    const [validationButtonDisabled, setValidationButtonDisabled] = useState(false);
    const [refreshTime, setRefreshTime] = useState(120000);
    const [isAllDataEmpty, setIsAllDataEmpty] = useState(true);
    const [isCoordinationPopupVisible, setIsCoordinationPopupVisible] = useState(false);
    const [marketNodeData, setMarketNodeData] = useState(null);
    const updateRefreshTime = (data) => {
        if ((isValidationInProgress(data) || isTemplatesInProgress(data))
            && document.visibilityState === 'visible') {
            setRefreshTime(5000);
        } else {
            setRefreshTime(30000);
        }
    }

    useEffect(() => {
            const fetchMarketNode = async () => {
                const mz = await getWriteAccessMarketNodeDetails(dataCollection.id, marketNode.id)
                let level5Result=(await getLevel5ValidationStatus(dataCollection, marketNode));
                await findByDCAndMarketNode(dataCollection, marketNode)
                    .then(res => {
                        if (res.status === 200) {
                            setMarketNodeData({id: res.content[0].id, name: res.content[0].marketNodeDTO.name})
                            checkMarketNodeStatus(mz)
                            updateRefreshTime(res.content)
                            setCategories(res.content);
                            updateValidationButtonStatus(res.content)
                            updatePublishButtonStatus(res.content,level5Result)
                            setStatusLV4(updateLevel4Status(res.content));
                            setCrumbs(Array.from(new Set([...crumbs, dataCollection?.name, res.content[0]['marketNodeDTO'].name])))
                            if (res.content.filter(x => x.statusDTO.code !== StatusCode.FILES_SKIPPED).length === 0) {
                                setIsAllDataEmpty(true);
                                setPublishBtnLabel("Confirm");
                            } else {
                                setIsAllDataEmpty(false);
                                setPublishBtnLabel("Publish Data");
                            }
                        } else {
                            onResponseError();
                        }
                    })
                    .catch(_ => {
                        onResponseError()
                    })
            }
            if (dataCollection && marketNode) {
                try {
                    fetchMarketNode().then(() => {
                        setIsLoading(false);
                    })
                } catch (e) {
                    onResponseError();
                }
            }

        }, []
    );

    const checkMarketNodeStatus = (marketNode) => {

        if (marketNode?.marketNodeStatus?.code === "PUBLISHED" || marketNode?.marketNodeStatus?.code === "DATA_PERSISTED") {
            showTimedError("Provide data is forbidden",
                "Access to providing data page is forbidden when market node is published or persisted.", 6000)
            navigate("/data_collections")
        }
    }

    const onResponseError = () => {
        onServerCallError("Error", "An error was occurred when searching categories data",
            () => {
                navigate(-1);
            })
    }


    useEffect(() => {
        if (urlParams?.dcName && urlParams.dcName.toLowerCase() !== normalizePathItem(dataCollection?.name)
            || urlParams?.marketNode && urlParams.marketNode.toLowerCase() !== normalizePathItem(marketNode?.name)) {
            removeFromSessionStorage("dataCollection")
            removeFromSessionStorage("marketNode")
            removeFromSessionStorage("dcMarketNodeId")
            navigate("/data_collections");
        }
    }, [])

    const updatePublishButtonStatus =  (data,level5Result) => {
        setBtnPublishVisibility(false);
        if(level5Result.content.hasValidationLevel5){
            var hasUpload =data.map(e => {
                if ((e['statusDTO'].context !== "LEVEL4_STEPS") || (e['statusDTO'].context === "LEVEL4_STEPS"
                    && (e['statusDTO'].code !== ("VALIDATION_SUCCESS") &&
                        e['statusDTO'].code !== StatusCode.FILES_SKIPPED))) {
                    return true;
                }
            })
            setBtnPublishVisibility(!level5Result.content.isValidationLevel5Valid && hasUpload.length>0);
        }
        else {
            data.map(e => {
                if ((e['statusDTO'].context !== "LEVEL4_STEPS") || (e['statusDTO'].context === "LEVEL4_STEPS"
                    && (e['statusDTO'].code !== ("VALIDATION_SUCCESS") &&
                        e['statusDTO'].code !== StatusCode.FILES_SKIPPED))) {
                    setBtnPublishVisibility(true);
                }
            })
        }

    }
    const updateValidationButtonStatus = (data) => {
        setValidationButtonDisabled(isLevel4ButtonDisabled(data.map(e => e['validationDTO'])))
    }

    const publish = () => {
        if (categories.filter(x => x.statusDTO.code !== StatusCode.FILES_SKIPPED).length === 0) {
            updateMarketNodeStatus(dataCollection?.id, marketNode.id, StatusCode.FILES_SKIPPED, StatusContext.MARKET_NODE_STEPS).then(r => {
                if (r.status === 200) {
                    showSuccess("Success", `Files upload skipped successfully !`);
                } else {
                    showError("Error", "An error was occurred when skipping upload files")
                }
                findByDCAndMarketNode(dataCollection, marketNode).then(() => {
                    navigate("/data_collections/" + dataCollection?.name);
                })
            })

        } else {
            publishData(categories, dataCollection?.id, dataCollection?.name).then(_ => {
                findByDCAndMarketNode(dataCollection, marketNode)
                    .then(data => {
                        setIsMarketNodeDataPublishing(true);

                        if (categories.filter(x => x.statusDTO.code !== StatusCode.FILES_SKIPPED).length === 0) {
                            setIsAllDataEmpty(true);
                            setPublishBtnLabel("Confirm");

                        } else {
                            setIsAllDataEmpty(false);
                            setPublishBtnLabel("Publish Data");

                        }
                        if (data.status === 200) {
                            getAllQueryFileNamesPerDataCollection(dataCollection?.id).then(res => {
                                if (res.length === 0) {
                                    showWarn("Info",
                                        "Market node data could not be persisted due to missing configuration.Please contact your administrator",
                                        10000)
                                    navigate("/data_collections/" + dataCollection?.name);
                                } else {
                                    setPublishBtnLabel(persistingLabel)
                                    persistData(categories, dataCollection?.id, dataCollection?.name).then(r => {
                                        if (r.status === 200) {
                                            showSuccess("Success", `market node Data is persisted`);
                                        } else if (r.status === 504) {
                                            showInfo("Info", "Persisting market node might take several minutes")
                                        } else {
                                            showError("Error", "An error was occurred when persisting market node data")
                                        }
                                        navigate("/data_collections/" + dataCollection?.name);
                                    });
                                }

                            });

                        } else {
                            showError("Error", "An error was occurred when searching categories data")
                        }

                    })
            })
        }

    }

   async function findByDCAndMN() {
        let level5Result=await getLevel5ValidationStatus(dataCollection,marketNode);
        findByDCAndMarketNode(dataCollection, marketNode)
            .then(res => {
                if (res.status === 200) {
                    updateRefreshTime(res.content)
                    updatePublishButtonStatus(res.content,level5Result);
                    updateValidationButtonStatus(res.content)
                    setCategories(res.content)
                    setStatusLV4(updateLevel4Status(res.content));
                    if (res.content.filter(x => x.statusDTO.code !== StatusCode.FILES_SKIPPED).length === 0) {
                        setIsAllDataEmpty(true);
                        setPublishBtnLabel("Confirm");
                    } else {
                        setIsAllDataEmpty(false);
                        setPublishBtnLabel("Publish Data");
                    }
                }
            })
    }

    const validateAllData = useCallback(() => {
        setStatusLV4(true);

        runMarketNodeValidation(dataCollection, marketNode)
            .then(() => {
                findByDCAndMN();
            });
    }, [])
    const updateLevel4Status = (data) => {
        let init = false;
        data.map(e => {
            if (e['validationDTO'].status.code === "VALIDATION_IN_PROGRESS"
                && e['validationDTO'].status.context === "LEVEL4_STEPS") {
                init = true;
            }
        })
        return init;
    }

    useEffect(() => {
        if (dataCollection && marketNode) {
            setTimeout(() => {
                findByDCAndMN()
            }, refreshTime);
        }
    });



    let content = isLoading ?
        (
            <LoadingScreen/>
        ) :
        (<Aux>
            <HeaderBreadCrumb items={crumbs}/>
            <PageDetails dcName={dataCollection?.name}
                         marketNode={marketNode?.name}
                         dcMarketNodeId={dcMarketNodeId}
            />
            <CategoriesData categoriesProps={categories}
                            modelPath={dataCollection?.modelPath}
                            statusLV4={statusLV4}
                            dataCollection={dataCollection}
                            setIsAllDataEmpty={setIsAllDataEmpty}
                            setValidationButtonDisabled={setValidationButtonDisabled}
                            marketNodeDataIsPublishing={isMarketNodeDataPublishing}
                            findByDCAndMN={findByDCAndMN}
                            setPublishBtnLabel={setPublishBtnLabel}
            />
            <ActionsList onPublishClick={publish} btnPublishVisibility={btnPublishVisibility} statusLV4={statusLV4}
                         onValidateAllDataClick={validateAllData}
                         marketNodeData={marketNodeData}
                         isCoordinationPopupVisible={isCoordinationPopupVisible}
                         setIsCoordinationPopupVisible={setIsCoordinationPopupVisible}
                         validationButtonDisabled={validationButtonDisabled}
                         publishBtnLabel={publishBtnLabel}
                         marketNodeDataIsPublishing={isMarketNodeDataPublishing}
                         isAllDataEmpty={isAllDataEmpty}
                         persistingLabel={persistingLabel}
                         dataCollection={dataCollection}/>
        </Aux>)
    return <div>{content}</div>
};
export default DataUploadBuilder;
