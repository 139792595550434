import React, {useEffect, useState} from "react";
import Aux from "../../../hoc/Auxiliary";
import ExportButton from "./ExportButton/ExportButton";
import EditButton from "./EditButton/EditButton";
import CheckInButton from "./CheckInButton/CheckInButton";
import {canExportDataWithSpecificFormat} from "../../../api/authorization/rules";
import {checkIfMarketNodeIsPublished} from "../../../api/MarketNode/marketNode-status";
import CoordinationButton from "./CoordinationButton/CoordinationButton";
import CoordinationDialog from "../Coordination/CoordinationDialog";
import {getCoordinations} from "../../../api/Coordination/coordination-service";

const BodyTemplate = (data) => {
    const [isCoordinationPopupVisible, setIsCoordinationPopupVisible] = useState(false);
    const [marketNodeData,setMarketNodeData]=useState(null)
   const [isCoordinationButtonVisible,setIsCoordinationButtonVisible]=useState(false)
    const checkVisibility = () => {
        return canExportDataWithSpecificFormat(data.dataCollection.participants, data.marketNodeData.marketNode);
    }
    const isDisabled = () => {
        return !checkIfMarketNodeIsPublished(data.marketNodeData.marketNodeStatus.code)
    }

    useEffect(() => {
        getCoordinations(data.marketNodeData?.id).then(r => {
            if (r.content?.length > 0) {
                setIsCoordinationButtonVisible(true)
            }
        })
    }, []);
    const hideCoordinationPopup = () => {
        setIsCoordinationPopupVisible(false);
    }
    return (<Aux>
        <div className="grid">
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <CheckInButton rowData={data}/>
            </div>
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <CoordinationButton isButtonVisible={isCoordinationButtonVisible} setIsCoordinationPopupVisible={() => {setIsCoordinationPopupVisible(true);setMarketNodeData({"id":data.marketNodeData.id,"name":data.marketNodeData.marketNode.name})}}/>
            </div>
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <ExportButton
                    listOfMarketNodes={[data.marketNodeData.marketNode]}
                    dcName={data.dataCollection.name}
                    dcId={data.dataCollection.id}
                    isDisplayed={checkVisibility()}
                    disabled={isDisabled()}
                    messageOnDisable={"Will be enabled when market node is published."}
                />
            </div>
            <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                <EditButton rowData={data}/>
            </div>
            <CoordinationDialog
                                isCoordinationPopupVisible={isCoordinationPopupVisible}
                                marketNodeData={marketNodeData}
                                dcId={data.dataCollection.id}
                                hidePopup={hideCoordinationPopup}></CoordinationDialog>
        </div>
    </Aux>)
}

export default BodyTemplate;
