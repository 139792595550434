import React from "react";
import {Button} from "primereact/button";
const Coordinationbutton = ({setIsCoordinationPopupVisible,isButtonVisible}) => {

    return (
        <>
            <Button visible={isButtonVisible} icon="pi pi-table"
                    tooltip="Coordination between market nodes"
                    tooltipOptions={{position: 'top', showOnDisabled: true}}
                    className=" p-button-rounded-sm p-button-text"
                    style={{height: '1.5rem', width: '2rem', color: '#487c84'}}
                    onClick={setIsCoordinationPopupVisible}
            />
        </>
    )


}

export default Coordinationbutton;
