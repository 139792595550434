import React, {useEffect, useState} from "react";
import classes from "./CategoryData.module.css";
import TemplateGeneration from "./TemplateGeneration/TemplateGeneration";
import DataProvisioning from "./DataProvisioning/DataProvisioning";
import {Panel} from 'primereact/panel';
import CategoryOverview from "./CategoryOverview/CategoryOverview";
import DataValidation from "./DataValidation/DataValidation";
import {formatDate} from "../../../../api/utils/DateFormatter";

const CategoryData = (props) => {
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [validationStatus, setValidationStatus] = useState(props.category['validationDTO'].status)
    const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false)
const[validationMsg,setValidationMsg]= useState("No data");
    useEffect(() => {
        if(props.category['validationDTO'].status.code==="TECHNICAL_ERROR") {
            setValidationMsg(props.category['validationDTO'].status.name)
        }
        setValidationStatus(props.category['validationDTO'].status)
    }, [props.category['validationDTO'].status, props.statusLV4])

    useEffect(() => {
        setTimeout(() => {
            props.findByDCAndMN()
        }, 100);
    }, [isFileUploaded])

    return (
        <Panel className={classes.panel} header={props.category['categoryDTO'].name}>
            <div className="grid">
                <CategoryOverview
                    validationStatus={validationStatus}
                    lastUpdate={props.category['uploadedIn'] !== null ? formatDate(new Date(props.category['uploadedIn'])) : ""}
                    lastUpdateBy={props.category.uploadedBy}/>
            </div>
            <br/>
            <div className="grid">
                <div className="col p-0">
                    <TemplateGeneration
                        dataCollection={props.dataCollection}
                        category={props.category}
                        marketNode={props.marketNode}
                        modelPath={props.modelPath}
                        findByDCAndMN={props.findByDCAndMN}
                    />
                </div>
                <div className="col p-0">
                    <DataProvisioning setFilePath={setFilePath}
                                      onFileUpload={setIsFileUploaded}
                                      status={validationStatus}
                                      setValidationStatus={setValidationStatus}
                                      category={props.category}
                                      dataCollection={props.dataCollection}
                                      marketNode={props.marketNode}
                                      statusLV4={props.statusLV4}
                                      findByDCAndMN={props.findByDCAndMN}
                                      setUploadBtnDisabled={setUploadBtnDisabled}
                                      uploadBtnDisabled={uploadBtnDisabled}
                                      marketNodeDataIsPublishing={props.marketNodeDataIsPublishing}
                                      setValidationButtonDisabled={props.setValidationButtonDisabled}
                                      setValidationMsg={setValidationMsg}
                    />
                </div>
                <div className="col p-0">
                    <DataValidation onFileUpload={setIsFileUploaded}
                                    isFileUploaded={isFileUploaded}
                                    categoryDetails={props.category}
                                    setFilePath={setFilePath}
                                    filePath={filePath}
                                    dataCollection={props.dataCollection}
                                    marketNode={props.marketNode}
                                    setStatus={setValidationStatus}
                                    status={validationStatus}
                                    validationResut={props.category['validationDTO']}
                                    setUploadBtnDisabled={setUploadBtnDisabled}
                                    modelPath={props.modelPath}
                                    statusLV4={props.statusLV4}
                                    setValidationButtonDisabled={props.setValidationButtonDisabled}
                                    categoriesProps={props.categoriesProps}
                                    validationMsg={validationMsg}
                                    setValidationMsg={setValidationMsg}
                    />
                </div>
            </div>
        </Panel>
    )
}

export default CategoryData;

