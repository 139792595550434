import keycloak from "../../keycloak";
import axios from "axios";
import {showError} from "../notification/ToastManager";
export async function getCoordinations(dcMnId) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.get(global.config.BACK_URL + "coordination/" + dcMnId, header)
            .then(res => {
                if (res.status === 200) {
                    response = res.data;
                }
                else {
                    showError("Error", "An error was occurred when retrieving coordination details");
                    response = res.data;
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving coordination details");
        response.status = e.response.status;
        response.content = {}
    }
    return response;
}
export async function notifyTsos(data) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "coordination/notify-tsos" , data, header)
            .then(res => {
                if (res.status === 200) {
                    response = res.data;
                }
                else {
                    showError("Error", "An error was occurred when notifiying TSOs");
                    response = res.data;
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when notifiying TSOs");
        response.status = e.response.status;
        response.content = {}
    }
    return response;
}