import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import "./CoordinationDialog.css";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {getCoordinations, notifyTsos} from "../../../api/Coordination/coordination-service";
import {showSuccess} from "../../../api/notification/ToastManager";
import {useEffect, useState} from "react";

const CoordinationDialog = ({isCoordinationPopupVisible, dcId, hidePopup, marketNodeData}) => {
    const [isButtonNotifyTsosLoading, setIsButtonNotifyTsosLoading] = useState(false);
    const [coordinationHeaderTitle, setCoordinationHeaderTitle] = useState("");
    const [coordinationData, setcoordinationData] = useState(null);
    const statusBody = (r) => {
        return (<i className="pi pi-circle-fill" data-pr-tooltip={r.status.name}
                   style={{'fontSize': '.8em', 'color': r.status.color}}>
                <Tooltip target=".pi"/></i>
        );
    }
    useEffect(() => {

            if (marketNodeData !== null) {
                setCoordinationHeaderTitle("Coordination details for " + marketNodeData.name)
                let coordinations = [];
                getCoordinations(marketNodeData?.id).then(r => {

                    r.content.forEach(coo => {
                        let marketNode = "";
                        if (coo.firstMarketNode.marketNode.name === marketNodeData.name) {
                            marketNode = coo.secondMarketNode.marketNode;
                        } else {
                            marketNode = coo.firstMarketNode.marketNode;
                        }
                        coordinations.push({"id": coo.id, "coordinator": marketNode, "status": coo.status})

                    })
                    setcoordinationData(coordinations);
                })
            }
        }
        , [isCoordinationPopupVisible])


    function notifyTsosCommand() {
        setIsButtonNotifyTsosLoading(true);
        var coordinators = coordinationData.filter(coordination => {
            return coordination.status.code === "VALIDATION_FAILED"
        })
            .map(co => {
                return {"coordinationId": co.id, "fixByMn": co.coordinator.name, "dcMnToBeNotified": co.coordinator.id,}
            })
        notifyTsos({"dcId": dcId, "coordinationDetailsParams": coordinators}).then(r => {
            setIsButtonNotifyTsosLoading(false);
            if (r.status === 200) {
                hidePopup();
                showSuccess("Success", "TSOs successfully notified !");
            }
        })
    }

    return (
        <Dialog header={coordinationHeaderTitle} headerClassName="dialog-header-centered" onHide={hidePopup}
                modal visible={isCoordinationPopupVisible}
                style={{width: '50vw'}}>
            <DataTable className={coordinationData==null?'data-table-container datatable-centered datatable-loading-style':'datatable-centered datatable-loading-style'}  value={coordinationData} loading={coordinationData===null}  >
                <Column field="coordinator.name" header="Coordinator" bodyClassName="text-center"></Column>
                <Column header="Validation status" bodyClassName="text-center" body={statusBody}></Column>
            </DataTable>
            {coordinationData?.filter(c => c.status.code === 'VALIDATION_FAILED').length > 0 ?
                <div style={{display: 'flex', justifyContent: 'right', paddingTop: '20px'}}>
                    <Button className="notifyBtnStyle" loading={isButtonNotifyTsosLoading}
                            onClick={notifyTsosCommand}> Notify TSOs to correct data </Button>
                </div> : null}
        </Dialog> )
}

export default CoordinationDialog;